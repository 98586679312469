<template>
  <el-dialog title="位置选择" :visible.sync="showDialog" width="50%" :close-on-click-modal="false" :close-on-press-escape="false" @close="handleClose">
    <el-form ref="form" :model="form" label-width="100px" style="padding-top:10px;">
      <el-form-item label="详细地址：" prop="address">
        <el-input v-model="form.province" style="width:120px;padding-right: 10px;" placeholder="省份" />
        <el-input v-model="form.city" style="width:120px;padding-right: 10px;" placeholder="城市" />
        <el-select v-model="form.address" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading"
          @change="addListChange">
          <el-option v-for="item in addressList" :key="item.value" :label="item.label" :value="item" />
        </el-select>
        <el-input v-model="form.addrPoint.lng" style="width:150px;padding-left: 10px;" placeholder="经度" />
        <el-input v-model="form.addrPoint.lat" style="width:150px;padding-left: 10px;padding-right: 20px;" placeholder="纬度" />
        <el-button type="primary" @click="saveData">确 定</el-button>
      </el-form-item>
      <el-form-item label="地图定位：">
        <div id="container" style="width:100%;height:500px;" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { mapInputSuggestion, pointToAddress } from '@/api/device'
import { getGroupDetail, updateDeviceGroupGeo, getAllDeviceGroup, removeDeviceFromGroup } from '@/api/deviceGroup'
let map, marker, polygon, drawingManager, lngLat, ap

export default {
  props: {
    // form: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       province: '河南省',
    //       city: '郑州', // 城市
    //       address: '', // 详细地址
    //       addrPoint: { // 详细地址经纬度
    //         lng: 117.234684,
    //         lat: 30.728493
    //       }
    //     }
    //   }
    // },
    dgSelectMapVisible: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      form: {
        province: '河南省',
        city: '郑州市', // 城市
        address: '', // 详细地址
        addrPoint: { // 详细地址经纬度
          lng: 117.234684,
          lat: 30.728493
        }
      },
      // groupId: '',
      // deviceId: 0,
      groupInfo: {},
      position: [
        {
          longitude: 0, // 经度
          latitude: 0,  // 纬度
          province: '',
          city: ''
        }
      ],
      showDialog: false,
      addressList: [],
      loading: false,
      input: '',
      map: '', // 地图实例
      mk: '', // Marker实例
      locationPoint: null,
      qqMap: null
    }
  },
  watch: {
    $route: {
      handler(route) {
        const query = route.query
        if (query && query.id != undefined) {
          this.groupId = query.id
        }
        console.log('route', this.groupId)
      },
      immediate: true
    },
    // 监听 addOrUpdateVisible 改变
    dgSelectMapVisible(oldVal, newVal) {
      console.log('dgSelectMapVisible', oldVal, newVal)
      this.showDialog = this.dgSelectMapVisible
      this.getGroupInfo()
    }
  },
  mounted() {

  },
  created() {
    console.log('created', this.groupId)
    this.qqMap = new qq.maps.Geolocation("7ZCBZ-M3W6J-5GOF3-FB4I3-ESVFJ-6AB7V", "管理后台")
    // await loadBMap('xCPgzZz3SMNGHGhKaf8ELdvzk2htFxEW') // 加载引入BMap
    // this.getGroupInfo()
    // console.log('mounted1', this.form.addrPoint.lat, this.form.addrPoint.lng)
    // if (this.form.addrPoint.lat == undefined && this.form.addrPoint.lng == undefined) {
    //   // 无坐标时显示当前位置
    //   console.log('无坐标时显示当前位置')
    //   geolocation.getLocation(this.showPosition, this.showErr)
    //   // geolocation.getIpLocation(this.showPosition, this.showErr)
    // }
    // else {
    //   // var geolocation = new qq.maps.Geolocation("7ZCBZ-M3W6J-5GOF3-FB4I3-ESVFJ-6AB7V", "管理后台")
    //   // geolocation.getLocation(this.showPosition, this.showErr)
    //   this.setMapData()
    //   // this.init()
    //   // this.setMapData()
    // }
  },
  methods: {
    getGroupInfo() {
      console.log('getGroupInfo', this.groupId)
      if (this.groupId == undefined || this.groupId == '') return

      this.listLoading = true

      getGroupDetail({ Id: parseInt(this.groupId) }).then((response) => {
        this.groupInfo = response.data
        this.form = {
          province: this.groupInfo.province, // 省份，根据坐标得来的字符串
          city: this.groupInfo.city, // 城市
          address: this.groupInfo.address,
          addrPoint: { // 详细地址经纬度
            lng: this.groupInfo.longitude,
            lat: this.groupInfo.latitude
          }
        }
        if (this.form.addrPoint.lng == undefined && this.form.addrPoint.lat == undefined) {
          // 无坐标时显示当前位置
          console.log('显示当前位置')
          // var geolocation = new qq.maps.Geolocation()
          this.qqMap.getIpLocation(this.showPosition, this.showErr)
        }
        else {
          this.setMapData()
        }

        this.listLoading = false
      })
    },
    // 初始化地图
    init() {
      console.log('init')
      // var geolocation = new qq.maps.Geolocation("7ZCBZ-M3W6J-5GOF3-FB4I3-ESVFJ-6AB7V", "管理后台")
      // var geolocation = new qq.maps.Geolocation()
      if (this.form.addrPoint.lat == undefined && this.form.addrPoint.lng == undefined) {
        // 无坐标时显示当前位置
        console.log('无坐标时显示当前位置')

        this.qqMap.getLocation(this.showPosition, this.showErr)
        // geolocation.getIpLocation(this.showPosition, this.showErr)
      }
      else {
        this.qqMap.getIpLocation(this.showPosition, this.showErr)
        this.setMapData()

        // //定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
        // var map = new qq.maps.Map(document.getElementById("container"), {
        //   center: new qq.maps.LatLng(39.916527, 116.397128),      // 地图的中心地理坐标。
        //   zoom: 8                                                 // 地图的中心地理坐标。
        // })
        // this.init()
        // this.setMapData()
      }

      // 定义地图中心点坐标
      // var myLatlng = new qq.maps.LatLng(this.form.addrPoint.lat, this.form.addrPoint.lng);
      // var myOptions = {
      //   zoom: 16,
      //   center: myLatlng,
      //   // mapStyleId: 'style1'
      //   mapTypeId: qq.maps.MapTypeId.ROADMAP
      //   // mapTypeId: windows.qq.maps.MapTypeId.ROADMAP
      // }
      // // 获取dom元素添加地图信息
      // map = new qq.maps.Map(document.getElementById("container"), myOptions)
      // this.mouseClick()
      // this.search()
      // //给定位的位置添加图片标注
      // marker = new qq.maps.Marker({
      //   position: myLatlng,
      //   map: map,
      //   draggable: true //允许鼠标拖动
      // })
    },
    //定位获得当前位置信息
    // getMyLocation() {
    //   var options = { timeout: 1000 * 30 }
    //   var geolocation = new qq.maps.Geolocation("7ZCBZ-M3W6J-5GOF3-FB4I3-ESVFJ-6AB7V", "管理后台")
    //   geolocation.getLocation(this.showPosition, this.showErr, options, options)
    // },
    showPosition(position) {
      console.log('showPosition', position)
      this.position.province = position.province
      this.position.city = position.city
      this.form.addrPoint.lat = position.lat
      this.form.addrPoint.lng = position.lng

      this.setMapData()
    },
    setMapData() {
      console.log('setMapData lng lat', this.form.addrPoint.lng, this.form.addrPoint.lat)
      var myLatlng = new qq.maps.LatLng(this.form.addrPoint.lat, this.form.addrPoint.lng)
      var myOptions = {
        zoom: 16,
        center: myLatlng,
        mapTypeId: qq.maps.MapTypeId.ROADMAP
      }
      //获取dom元素添加地图信息
      map = new qq.maps.Map(document.getElementById("container"), myOptions)
      //给定位的位置添加图片标注
      marker = new qq.maps.Marker({
        position: myLatlng,
        map: map,
        draggable: true //允许鼠标拖动
      })
      this.mouseClick()
      this.search()
    },
    //定位失败再请求定位，测试使用
    showErr() {
      console.error('定位失败，请检查网络设置')
      // this.$message.error('定位失败，请检查网络设置')
      // this.getMyLocation()
      // this.setMapData()
    },
    // 鼠标点击获取标记位置事件
    mouseClick() {
      const _this = this
      qq.maps.event.addListener(map, 'click', function (event) {
        if (!marker) {
          marker = new qq.maps.Marker({
            position: event.latLng,
            map: map
          })
          return
        }
        marker.setPosition(event.latLng)
        // console.log(event)
        // _this.position.latitude = event.latLng.getLat();
        // _this.position.longitude = event.latLng.getLng();
        _this.form.addrPoint.lat = event.latLng.getLat()
        _this.form.addrPoint.lng = event.latLng.getLng()
        console.log('mouseClick', _this.form.addrPoint)
        pointToAddress({
          longitude: _this.form.addrPoint.lng,
          latitude: _this.form.addrPoint.lat
        }).then((resp) => {
          _this.form.province = resp.data.address_component?.province || '河南省'
          _this.form.city = resp.data.address_component?.city || '郑州市'
          _this.form.address = resp.data.address
        })
      })
    },
    /**搜索地点 */
    search() {
      ap = new window.qq.maps.place.Autocomplete(document.getElementById('place'));
      var keyword = "";
      //调用Poi检索类。用于进行本地检索、周边检索等服务。
      var searchService = new window.qq.maps.SearchService({
        complete: function (results) {
          if (results.type === "CITY_LIST") {
            searchService.setLocation(results.detail.cities[0].cityName)
            searchService.search(keyword)
            return
          }
          var pois = results.detail.pois
          var latlngBounds = new window.qq.maps.LatLngBounds()
          for (var i = 0, l = pois.length; i < l; i++) {
            var poi = pois[i];
            latlngBounds.extend(poi.latLng)
            var marker = new window.qq.maps.Marker({
              map: map,
              position: poi.latLng
            })

            marker.setTitle(poi.name)
          }
          map.fitBounds(latlngBounds)
        }
      });
      //添加监听事件
      window.qq.maps.event.addListener(ap, "confirm", function (res) {
        keyword = res.value
        searchService.search(keyword)
      })
    },
    // 8-2、选择地址
    addListChange(item) {
      // console.log(item)
      this.form.province = item.province
      this.form.city = item.city
      this.form.address = item.address
      this.form.addrPoint.lat = item.location.lat
      this.form.addrPoint.lng = item.location.lng
      this.showPosition(item.location)
      // this.map.clearOverlays()
      // this.mk = new BMap.Marker(item.point)
      // this.map.addOverlay(this.mk)
      // this.map.panTo(item.point)
    },
    remoteMethod(query) {
      console.log(query)
      this.loading = true
      mapInputSuggestion({
        keyword: query
      }).then((resp) => {
        this.loading = false
        this.addressList = []
        for (const item of resp.data) {
          console.log(item)
          const province = item.province || '河南省'
          const city = item.city || '郑州市'
          this.addressList.push({
            label: item.address, value: item.id,
            address: item.address, province: province,
            city: city, location: item.location
          })
        }
      })
    },
    saveData() {
      if (this.form.address == '' || this.form.addrPoint.lng == '' || this.form.addrPoint.lat == '') {
        this.$message.error('请选择坐标信息')
        return
      }
      updateDeviceGroupGeo({
        id: parseInt(this.groupId),
        province: this.form.province,
        city: this.form.city,
        address: this.form.address,
        longitude: parseFloat(this.form.addrPoint.lng),
        latitude: parseFloat(this.form.addrPoint.lat)
      }).then((resp) => {
        this.$message.success('修改成功')
        this.handleClose()
      })
    },
    // 弹出框关闭后触发
    handleClose() {
      // 子组件调用父组件方法，并传递参数
      this.$emit('closeSelectMap', false)
      // this.$emit('update:dgSelectMapVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.autoAddressClass {
  li {
    i.el-icon-search {
      margin-top: 11px;
    }
    .mgr10 {
      margin-right: 10px;
    }
    .title {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .address {
      line-height: 1;
      font-size: 12px;
      color: #b4b4b4;
      margin-bottom: 5px;
    }
  }
}
</style>
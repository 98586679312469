<template>
  <div class="all">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header" class="clearfix">
        <span>所在地分布</span>
      </div>
      <div id="main2" :class="className" :style="{height:height,width:width}" />
    </el-card>
  </div>
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import { getDeviceStatByCity } from '@/api/device'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      getDeviceStatByCity().then(res => {
        const seriesData = []
        for (const item of res.data) {
          seriesData.push({ value: item.statCount, name: item.city || '其他' })
        }
        this.initChart(seriesData)
      })
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(seriesData) {
      this.chart = echarts.init(document.getElementById('main2'), 'macarons')

      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        legend: {
          left: 'center',
          bottom: '10'
        },
        series: [
          {
            name: '设备数量',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: seriesData,
            // animationEasing: 'cubicInOut',
            // animationDuration: 2600
          }
        ]
      })
    }
  }
}
</script>

<template>
  <div class="all">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header" class="clearfix">
        <span>数据采集量日统计</span>
      </div>
      <div id="main3" :class="className" :style="{height:height,width:width}" />
    </el-card>
  </div>
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import { getCollectStatData } from '@/api/device'

const animationDuration = 6000

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      getCollectStatData().then(res => {
        const xAxisData = []
        const yAxisData = []
        for (const item of res.data) {
          xAxisData.push(item.day)
          yAxisData.push(item.count)
        }
        this.initChart(xAxisData, yAxisData)
      })
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(xAxisData, yAxisData) {
      this.chart = echarts.init(document.getElementById('main3'), 'macarons')

      this.chart.setOption({
        // title: {
        //   text: '数据采集量日统计',
        //   left: '60',
        //   textStyle: {
        //     fontSize: 16
        //   }
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 10,
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          // data: ['2022-05-10', '2022-05-1', '2022-05-12', '2022-05-13', '2022-05-14', '2022-05-15', '2022-05-16'],
          data: xAxisData,
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: 'pageA',
          type: 'bar',
          stack: 'vistors',
          barWidth: '60%',
          // data: [79, 52, 200, 334, 390, 330, 220],
          data: yAxisData,
          animationDuration
        }]
      })
    }
  }
}
</script>

import request from '@/utils/request'

/**
 * 获取所有数据列表，分页
 * @param {*} query
 */
export function fetchDeviceGroupList(data) {
  return request({
    url: '/v1/get/device/group/list',
    method: 'post',
    data
  })
}

export function createDeviceGroup(data) {
  return request({
    url: '/v1/create/device/group',
    method: 'post',
    data
  })
}

export function updateDeviceGroup(data) {
  return request({
    url: '/v1/update/device/group',
    method: 'post',
    data
  })
}

export function updateDeviceGroupGeo(data) {
  return request({
    url: '/v1/update/device/group/geo',
    method: 'post',
    data
  })
}

export function deleteDeviceGroup(data) {
  return request({
    url: '/v1/delete/device/group',
    method: 'post',
    data
  })
}

export function addDeviceInGroup(data) {
  return request({
    url: '/v1/add/device/in/group',
    method: 'post',
    data
  })
}

export function addDeviceInGroupByCondition(data) {
  return request({
    url: '/v1/add/device/group/by/condition',
    method: 'post',
    data
  })
}

export function getDeviceListNoGroup(data) {
  return request({
    url: '/v1/get/device/list/no/group',
    method: 'post',
    data
  })
}

export function getGroupDetail(data) {
  return request({
    url: '/v1/device/group/detail',
    method: 'post',
    data
  })
}

export function getAllDeviceGroup(data) {
  return request({
    url: '/v1/get/all/device/group',
    method: 'post',
    data
  })
}

export function removeDeviceFromGroup(data) {
  return request({
    url: '/v1/remove/device/from/group',
    method: 'post',
    data
  })
}

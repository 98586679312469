import request from '@/utils/request'

/**
 * 获取所有数据列表，分页
 * @param {*} query
 */
export function fetchDeviceList(data) {
  return request({
    url: '/v1/get/device/list',
    method: 'post',
    data
  })
}

export function createDevice(data) {
  return request({
    url: '/v1/create/device',
    method: 'post',
    data
  })
}

export function updateDevice(data) {
  return request({
    url: '/v1/update/device',
    method: 'post',
    data
  })
}

/**
 * 更新设备的扩展信息
 * @param data
 */
export function updateDeviceExt(data) {
  return request({
    url: '/v1/update/device/ext',
    method: 'post',
    data
  })
}

export function deleteDevice(data) {
  return request({
    url: '/v1/delete/device',
    method: 'post',
    data
  })
}

export function importDevice(data) {
  return request({
    url: '/v1/import/device',
    method: 'post',
    data
  })
}

export function getDistinctCity(data) {
  return request({
    url: '/v1/get/distinct/city',
    method: 'post',
    data
  })
}

// export function getAllDeviceType(data) {
//   return request({
//     url: '/v1/get/all/device/type',
//     method: 'post',
//     data
//   })
// }

// export function getAllDeviceModel(data) {
//   return request({
//     url: '/v1/get/all/device/model',
//     method: 'post',
//     data
//   })
// }

export function getCurData(data) {
  return request({
    url: '/v1/get/cur/data',
    method: 'post',
    data
  })
}

export function getEventLog(data) {
  return request({
    url: '/v1/get/event/log',
    method: 'post',
    data
  })
}

export function getDeviceRemoveLog(data) {
  return request({
    url: '/v1/get/remove/log',
    method: 'post',
    data
  })
}

export function getDeviceInfoByCcid(data) {
  return request({
    url: '/v1/get/device/info/by/ccid',
    method: 'post',
    data
  })
}

export function getDeviceInfo(data) {
  return request({
    url: '/v1/get/device/info',
    method: 'post',
    data
  })
}

/**
 * 获取所有数据列表，分页
 * @param {*} query
 */
export function getCommandList(data) {
  return request({
    url: '/v1/get/command/list',
    method: 'post',
    data
  })
}

export function createCommand(data) {
  return request({
    url: '/v1/create/command',
    method: 'post',
    data
  })
}

export function updateCommand(data) {
  return request({
    url: '/v1/update/command',
    method: 'post',
    data
  })
}

export function deleteCommand(data) {
  return request({
    url: '/v1/delete/command',
    method: 'post',
    data
  })
}

export function getDeviceCommList(data) {
  return request({
    url: '/v1/get/device/comm/log',
    method: 'post',
    data
  })
}

export function getDeviceHisDataList(data) {
  return request({
    url: '/v1/get/device/his/data',
    method: 'post',
    data
  })
}

export function getDeviceDayDataList(data) {
  return request({
    url: '/v1/get/device/day/data',
    method: 'post',
    data
  })
}

export function getVersionStat(data) {
  return request({
    url: '/v1/get/version/stat',
    method: 'post',
    data
  })
}

export function getHomePageData(data) {
  return request({
    url: '/v1/get/home/page/data',
    method: 'post',
    data
  })
}

export function getDeviceStateStatData(data) {
  return request({
    url: '/v1/get/home/page/state/stat/data',
    method: 'post',
    data
  })
}

export function getDeviceStatByCity(data) {
  return request({
    url: '/v1/get/device/stat/by/city',
    method: 'post',
    data
  })
}

export function getCollectStatData(data) {
  return request({
    url: '/v1/get/home/page/collect/stat/data',
    method: 'post',
    data
  })
}

export function exportDevice(data) {
  return request({
    url: '/v1/export/device',
    method: 'post',
    responseType: 'arraybuffer',
    data
  })
}

// export function exportHistoryData(data) {
//   return request({
//     url: '/v1/export/device/history/data',
//     method: 'post',
//     responseType: 'arraybuffer',
//     data
//   })
// }

export function mapInputSuggestion(data) {
  return request({
    url: '/v1/map/suggestion',
    method: 'post',
    data
  })
}


export function pointToAddress(data) {
  return request({
    url: '/v1/point/to/address',
    method: 'post',
    data
  })
}


export function getWorkOrderList(data) {
  return request({
    url: '/v1/get/work/order/list/wx',
    method: 'post',
    data
  })
}

export function deleteWorkOrder(data) {
  return request({
    url: '/v1/delete/work/order',
    method: 'post',
    data
  })
}

export function createQueryParamPlan(data) {
  return request({
    url: '/v1/send/read/param/cmd',
    method: 'post',
    data
  })
}

export function createSetParamPlan(data) {
  return request({
    url: '/v1/send/write/param/cmd',
    method: 'post',
    data
  })
}

export function createClearDataCmdPlan(data) {
  return request({
    url: '/v1/send/clear/data/cmd',
    method: 'post',
    data
  })
}

export function addRemoteCmdPlan(data) {
  return request({
    url: '/v1/add/remote/cmd/plan',
    method: 'post',
    data
  })
}

export function queryHisDataCmdPlan(data) {
  return request({
    url: '/v1/query/his/data/cmd',
    method: 'post',
    data
  })
}
export function getCmdPlan(data) {
  return request({
    url: '/v1/get/cmd/plan',
    method: 'post',
    data
  })
}

export function cancelCmdPlan(data) {
  return request({
    url: '/v1/cancel/cmd/plan',
    method: 'post',
    data
  })
}







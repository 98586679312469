import request from '@/utils/request'

export function sendSmsVerifyCode(data) {
  return request({
    url: '/v1/send/sms/verifycode',
    method: 'post',
    data
  })
}

export function login(data) {
  return request({
    url: '/v1/login',
    method: 'post',
    data
  })
}

export function createVerifyCode() {
  return request({
    url: '/v1/create/verify/code',
    method: 'post'
  })
}

/**
 * 根据微信code，后端获取openid，根据openid自动登录
 * @param {*} data
 * @returns
 */
export function loginByWxCode(data) {
  // console.log('loginByWxCode22', data);
  return request({
    url: '/v1/user/login/by/wx/code',
    method: 'post',
    data
  })
}

/**
 * 根据钉钉code，后端获取openid，根据openid自动登录
 * @param {*} data
 * @returns
 */
export function loginByDingdingCode(data) {
  return request({
    url: '/v1/user/login/by/dingding/code',
    method: 'post',
    data
  })
}
export function getInfo(data) {
  console.log('getInfo', data)
  return request({
    url: '/v1/get/user/info/by/token',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/v1/user/logout',
    method: 'post'
  })
}

/**
 * 获取所有数据列表
 * @param {*} query
 */
export function fetchOperatorList(data) {
  return request({
    url: '/v1/get/operator/list',
    method: 'post',
    data
  })
}

export function createOperator(data) {
  return request({
    url: '/v1/create/operator',
    method: 'post',
    data
  })
}

export function updateOperator(data) {
  return request({
    url: '/v1/update/operator',
    method: 'post',
    data
  })
}

/**
 *
 * @param {*} data
 * @returns
 */
export function deleteOperator(data) {
  return request({
    url: '/v1/delete/operator',
    method: 'post',
    data
  })
}

/**
 *
 * @param {*} data
 * @returns
 */
export function modifyPwd(data) {
  return request({
    url: '/v1/modify/pwd',
    method: 'post',
    data
  })
}

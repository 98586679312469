<template>
  <div class="all">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header" class="clearfix">
        <span>设备状态统计</span>
      </div>
      <div id="main1" :class="className" :style="{height:height,width:width}" />
    </el-card>
  </div>
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import { getDeviceStateStatData } from '@/api/device'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      const statusDesc = { '0': '待接入', '1': '正常', '2': '异常' }

      getDeviceStateStatData().then(res => {
        const seriesData = []
        for (const item of res.data) {
          seriesData.push({ value: item.statCount, name: statusDesc[item.status] || '其他' })
        }
        this.initChart(seriesData)
      })
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(seriesData) {
      this.chart = echarts.init(document.getElementById('main1'), 'macarons')

      this.chart.setOption({
        // title: {
        //   text: '设备状态统计',
        //   textStyle: {
        //     fontSize: 16
        //   }
        // },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            // avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true
              // position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                // fontSize: '40',
                fontWeight: 'bold'
              }
            },
            // labelLine: {
            //   show: true
            // },
            // data: [
            //   { value: 1048, name: '待接入' },
            //   { value: 735, name: '在线' },
            //   { value: 580, name: '离线' }
            // ]
            data: seriesData
          }
        ]
      })
    }
  }
}
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: { model: _vm.loginForm, rules: _vm.loginRules },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("烟箱积温云管理平台")]),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "el-input",
                {
                  attrs: { type: "text", placeholder: "账号" },
                  model: {
                    value: _vm.loginForm.loginCode,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.loginForm,
                        "loginCode",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "loginForm.loginCode",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "user" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "el-input",
                {
                  attrs: { type: "password", placeholder: "密码" },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.loginForm,
                        "password",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "loginForm.password",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "password" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "code" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "60%" },
                  attrs: {
                    "auto-complete": "off",
                    placeholder: "验证码，不区分大小写",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleLogin($event)
                    },
                  },
                  model: {
                    value: _vm.loginForm.code,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.loginForm,
                        "code",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "loginForm.code",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "password" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "login-code",
                  attrs: { title: "看不清就点一下" },
                  on: {
                    click: function ($event) {
                      return _vm.refreshCode()
                    },
                  },
                },
                [_c("div", { attrs: { id: "dvCode" } }, [_vm._v("验证码")])]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v("登 录")])
                    : _c("span", [_vm._v("登 录 中...")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-login-footer" }, [
      _c("span", [
        _vm._v("Copyright © 2018-2022 linying All Rights Reserved."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_c("span", [_vm._v("最新数据")])]
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.list } },
            [
              _c("el-table-column", {
                attrs: { label: "IMEI", "min-width": "150px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.imei))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "设备名称", "min-width": "150px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.deviceName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "目标温度(℃)", "min-width": "150px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.targetValue))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "环境温度(℃)", "min-width": "150px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v(_vm._s(row.envValue))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "采样时间", width: "160px", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("parseTime")(row.freezeAt))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "上报时间", width: "160px", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("parseTime")(row.createdAt))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
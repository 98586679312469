var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [_c("span", [_vm._v("触发日志")])]
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.list } },
        [
          _c("el-table-column", {
            attrs: { label: "IMEI", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.imei))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "设备类型", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.deviceTypeName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "报警描述", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.eventContent))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "触发时间", width: "160px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            row.createdAt,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }